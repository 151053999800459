import { AikyamClient, WaltersonClient } from 'src/features/Clients';

import { AxiosError } from 'axios';
import type {
    ArrangeRequest,
    ArrangeResponse,
    AuthenticateError,
    AuthenticateRequest,
    AuthenticateResponse,
    CompleteRequest,
    CompleteResponse,
    VerifyAttestationRequest,
    VerifyAttestationResponse,
} from './types';

/** Walterson Shim for HSID Users

 Serves as a bridge that converts legacy aa-web inputs to Walterson Edge API inputs and
 Walterson Edge API outputs to legacy aa-web outputs. */
// TODO: Convert to regular functions
const ShimService = {
    /** Performs preparation steps for other Walterson services
     to be ready to perform adaptive authentication by provisioning IdP session (IS cookie).

     Additionally, it performs few verification steps:
     - Unwraps the profiling bond from the RBA session token
     - Verifies the callback URL */
    arrange: (data: ArrangeRequest, useAikyamEndpoint = false): Promise<ArrangeResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<ArrangeResponse>('/rest/public/shim/aa-web/arrange', data)
            .then(response => response.data),
    /** Verifies successful completion of adaptive authentication
     and responds with *aaToken* necessary to move to the next step of login
     or other process. */
    complete: (data: CompleteRequest, useAikyamEndpoint = false): Promise<CompleteResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<CompleteResponse>('/rest/public/shim/aa-web/complete', data)
            .then(response => response.data),
    verify: (data: VerifyAttestationRequest, useAikyamEndpoint = false): Promise<VerifyAttestationResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .put<VerifyAttestationResponse>('/rest/client/shim/aa-web/attestation/verify', data)
            .then(response => response.data),
    authenticateLogin: (data: AuthenticateRequest, useAikyamEndpoint = false): Promise<AuthenticateResponse> => {
        const requestPromise = useAikyamEndpoint
            ? AikyamClient.post<AuthenticateResponse>('/rest/public/account/v2/login', data)
            : WaltersonClient.post<AuthenticateResponse>('/rest/public/shim/account/v2/authenticate', data);
        return (
            requestPromise
                .then(response => response.data)
                // TODO: Handle request errors
                .catch((err: AxiosError<AuthenticateError>) => Promise.reject(err.response?.data))
        );
    },
};

export default ShimService;
