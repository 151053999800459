import { useEffect, useState } from 'react';
import deepEqual from 'fast-deep-equal';

import noop from 'src/utils/noop';
import setProp from 'src/utils/setProp';

import useConstant from './useConstant';

type MilestoneGoal = boolean | number;
type MilestonesData = Record<string, MilestoneGoal>;
type MilestoneTrigger = () => void;
type MilestonesTriggers = Record<string, MilestoneTrigger>;

const determineInitialMilestoneState = (goal: MilestoneGoal) => (typeof goal === 'boolean' ? !goal : 0);

/**
 * The useMilestones custom hook allows you to track state for the completion of a single state that is based on
 * multiple goals (e.g. one user interaction must happen once and another interaction must happen n times).
 *
 * The hook takes a dict of milestone names and target boolean or number goals and an optional callback function that
 * will be called when all milestones are completed. It returns a dict with keys that match the milestones dict that map
 * to functions which will progress that milestone (boolean goals are flipped, number goals are incremented/decremented
 * depending on whether they are positive or negative goals values) and a boolean signaling the completion of the
 * milestones.
 *
 * <code>
 * // Contrived example to determine whether a form field has been "touched" (it has received focus and had more than 5
 * // changes to its value)
 * const [
 *   { blurred: fieldWasBlurred, changedCount: fieldValueWasChanged },
 *   isTouched,
 * ] = useMilestones({ touched: true, changed: 5 }, () => { console.log('Field was touched'); });
 *
 * <input
 *   onChange={ev => {
 *     const { name, value } = ev.target;
 *     // ... other state changes
 *     fieldValueWasChanged();
 *   }}
 *   onBlur={ev => {
 *     fieldWasBlurred();
 *   }}
 * />
 *
 * {isTouched ? <p>'Field was touched'</p> : null}
 * </code>
 *
 * @param milestones A dict of milestone names and target completion values (goals)
 * @param onMilestonesCompleted An optional callback function that will be called when all the milestones are completed
 * @returns A tuple where the first index is the milestones progress triggers and the second index is a boolean that
 *          will be true when all the milestone goals are reached and false prior to that.
 */
export const useMilestones = (
    milestones: MilestonesData,
    onMilestonesCompleted: () => void = noop
): [MilestonesTriggers, boolean] => {
    // Cache the original milestones and completion callback
    const finalGoal = useConstant(milestones);
    const completionCallback = useConstant(onMilestonesCompleted);

    // We need to iterate on the milestones twice so save calling Object.entries() twice
    const milestonesEntries = Object.entries(finalGoal);
    // Generate the internal milestone tracking state. Set with function signature for initial state to prevent
    // recalculation on re-renders:
    // - Booleans are initialized as their inverse
    // - Numbers are initialized at 0
    const [milestonesState, setMilestoneState] = useState(() =>
        milestonesEntries.reduce<MilestonesData>(
            (state, [name, goal]) => setProp(state, name, determineInitialMilestoneState(goal)),
            {}
        )
    );

    // Produce the returned hook API to trigger the completion/progress of milestone goals. Use function style useState
    // initialState to prevent recalculation on re-renders.
    const [triggers] = useState(() =>
        milestonesEntries.reduce<MilestonesTriggers>(
            (ts, [name, goal]) =>
                setProp(ts, name, () => {
                    setMilestoneState(current => {
                        const currentMilestone = current[name];
                        // If the current value for the milestone doesn't match the goal, perform the update logic
                        if (currentMilestone !== goal) {
                            let nextValue: MilestoneGoal | null = null;
                            // Boolean goals get flipped
                            if (typeof currentMilestone === 'boolean') {
                                nextValue = !currentMilestone;
                            }

                            // Number goals get incremented or decremented
                            if (typeof currentMilestone === 'number') {
                                nextValue = goal < 0 ? currentMilestone - 1 : currentMilestone + 1;
                            }

                            // If a goal value was updated, update the internal state
                            if (nextValue != null) {
                                return {
                                    ...current,
                                    [name]: nextValue,
                                };
                            }
                        }

                        return current;
                    });
                }),
            {}
        )
    );

    // Compare progress state to original milestones to determine completion
    const areMilestonesCompleted = deepEqual(finalGoal, milestonesState);
    // If completion happened, trigger callback
    useEffect(() => {
        if (areMilestonesCompleted) {
            completionCallback();
        }
    }, [areMilestonesCompleted, completionCallback]);

    // Return boolean flag for if completion successful and triggers. Triggers come first because those will always be
    // used whereas code may use completion state and/or completion callback so completion state could be ignored
    return [triggers, areMilestonesCompleted];
};
