import React from 'react';
import PropTypes from 'prop-types';

import { CloseIcon } from 'src/UI/Icon';

import { withContent } from 'src/components/ContentProvider';
import Button from './Button';

import classes from './CrossButton.module.scss';

const propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
};

const defaultProps = {
    id: undefined,
    onBlur: undefined,
};

const mapContentToProps = ({ LblClearInput }, { label }) => ({
    // NOTE: This overrides the `label` prop
    label: LblClearInput.replace('[FIELD_NAME]', label),
});

const CrossButton = ({ id, label, onClick, onBlur, ...rest }) => (
    <Button
        variant="icon"
        id={id}
        className={classes.root}
        clicked={onClick}
        onBlur={onBlur}
        aria-label={label}
        {...rest}
    >
        <CloseIcon />
    </Button>
);

CrossButton.propTypes = propTypes;
CrossButton.defaultProps = defaultProps;

export default withContent(mapContentToProps)(CrossButton);
export { CrossButton };
