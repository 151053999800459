import type { ReactElement } from 'react';
import React from 'react';

import format from 'src/utils/format';

import FieldError from './FieldError';
import { FieldState, getFieldFirstError, isFieldTouched } from './hooks/useFieldState';

/**
 * Generate an ID value for the form field error message element
 */
export const getFieldErrorId = (fieldId: string): string => `${fieldId}-error`;

/**
 * Generate an ID value for the form field valid message element
 */
export const getFieldValidId = (fieldId: string): string => `${fieldId}-valid`;

// Because we can modify the field's value with the Clear button, we want to trigger the change event on the <input> to
// perform all state updates attached to its `onChange` handler. Triggering a manual change event in React is tricky but
// this solution works.
// @see https://stackoverflow.com/a/46012210
export const triggerChangeEvent = (element: HTMLInputElement | HTMLSelectElement, newValue: string): void => {
    if (typeof Object.getOwnPropertyDescriptor !== 'undefined') {
        const nativeInputValueDescriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
        if (nativeInputValueDescriptor?.set) {
            nativeInputValueDescriptor.set.call(element, newValue);
            element.dispatchEvent(new Event('input', { bubbles: true }));
        }
    }
};

// TODO Move into different file?
export type RenderErrorData = {
    fieldState: FieldState;
    fieldId: string;
    wasFormSubmitted: boolean;
};

/**
 * The default renderError prop for form field components
 */
export const defaultRenderError = ({ fieldState, fieldId, wasFormSubmitted }: RenderErrorData): ReactElement | null =>
    React.createElement(
        FieldError,
        { id: getFieldErrorId(fieldId), fieldId },
        wasFormSubmitted || isFieldTouched(fieldState) ? getFieldFirstError(fieldState) : null
    );

export const createTemplatedFieldErrorMessage =
    (templatedMessage: string) =>
    (fieldLabel: string): string =>
        format(templatedMessage, { FIELD_NAME: fieldLabel });
