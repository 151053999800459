// These must be the first lines in src/index.js
import './initiateStoreUserData.js';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './OptumPixelCookie.js';
import 'src/sass/index.scss';
// TODO Remove and uncomment dynamic import in `App.js`
import 'src/sass/rds.scss';

import App from './App';

ReactDOM.render(
    <BrowserRouter basename={process.env.__BASENAME}>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
