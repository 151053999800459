export enum LogLevel {
    DEBUG = 10,
    INFO = 20,
    WARN = 30,
    ERROR = 40,
    FATAL = 50,
    /** @note This level should never send a log entry and is used to disable a specific Log Destination handler */
    DISABLED = 100,
}
export type CallableLogLevel = Exclude<LogLevel, LogLevel.DISABLED>;

/** @todo Figure out how to generate this from `enum LogLevel` above */
export type LogLevelName = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL';
