import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'src/UI/Button';
import { withContent } from 'src/components/ContentProvider';
import { QaClassNames } from 'src/app/QaConstants';

import { ReactComponent as CloseX } from 'src/assets/icons/Close.svg';

import classes from './CloseButton.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    modalTitleId: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const mapContentToProps = {
    label: 'LblClose',
};

const CloseButton = ({ id, modalTitleId, handleClose, label }) => (
    <Button
        id={id}
        type="button"
        variant="graphic"
        className={`${classes.button} ${QaClassNames.MODAL_CLOSE_ACTION}`}
        aria-label={label}
        clicked={handleClose}
        aria-describedby={modalTitleId}
    >
        <CloseX className={classes.icon} />
    </Button>
);

CloseButton.propTypes = propTypes;

export default withContent(mapContentToProps)(CloseButton);
export { CloseButton };
