import { AikyamClient, WaltersonClient } from 'src/features/Clients';

import { loadScript } from 'src/utils/html';
import type { AxiosResponse } from 'axios';

import { logger } from 'src/app/Logger';
import { seconds } from 'src/utils/dateTimeUtility';
import type {
    AttestationResponse,
    AuthenticateRequest,
    AuthenticateResponse,
    AuthenticatorResponse,
    ChallengeRequest,
    ChallengeResponse,
    PhoneAttestationRequest,
    QuestionAttestationRequest,
    AuthenticatorByNamersRequest,
    ChallengeRequestParams,
    ProfilingMetadataRequestParams,
    ProfilingMetadataResponse,
    AikyamTmxInfoRequestParams,
    AikyamTmxInfoResponseData,
} from './types';

export type RBAProfilingStatus = 'SKIPPED' | 'PASSED' | 'SCRIPT_LOAD_TIMED_OUT' | 'PROFILING_TIMED_OUT' | 'ERRORED';

export type RBAProfilingResult = { status: RBAProfilingStatus; error?: Error };

/** Walterson User Edge Service */
const OBAService = {
    /** Login to user account and provision IS cookie. */
    authenticate: (data: AuthenticateRequest, useAikyamEndpoint = false): Promise<AuthenticateResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<AuthenticateResponse>('/rest/public/account/v2/authenticate', data)
            .then(response => response.data),

    /** Request authenticators associated with logged-in user. */
    getAuthenticators: (useAikyamEndpoint = false): Promise<AuthenticatorResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .get<AuthenticatorResponse>('/rest/protected/account/v2/authenticator')
            .then(response => response.data),

    /** Request challenges for the given authentication method. */
    getChallenge: (
        data: ChallengeRequest,
        params: ChallengeRequestParams,
        useAikyamEndpoint = false
    ): Promise<ChallengeResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<ChallengeResponse>('/rest/public/account/v2/authenticator/challenge', data, { params })
            .then(response => response.data),

    /** Provide user response to challenges. */
    attest: (
        data: (PhoneAttestationRequest | QuestionAttestationRequest)[],
        useAikyamEndpoint = false
    ): Promise<AttestationResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<AttestationResponse>('/rest/public/account/v2/authenticator/attestation', data)
            .then(response => response.data),

    /** Fetch authenticator methods by the account namers. */
    findAuthenticator: (
        data: AuthenticatorByNamersRequest,
        useAikyamEndpoint = false
    ): Promise<AuthenticatorResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .put<AuthenticatorResponse>('/rest/public/account/v2/authenticator/find', data)
            .then(response => response.data),

    getProfileMetadata: (params: ProfilingMetadataRequestParams): Promise<ProfilingMetadataResponse> =>
        WaltersonClient.get<ProfilingMetadataResponse>('/rest/public/account/v2/profile/metadata', {
            params,
        }).then(response => response.data),

    getAikyamTmxInfoData: (params: AikyamTmxInfoRequestParams): Promise<AikyamTmxInfoResponseData> =>
        AikyamClient.get<AikyamTmxInfoResponseData>('/rest/protected/tmx/info', {
            params,
        }).then(response => response.data),

    // Note: timeoutS is used for both loading of script and profiling independently. Maximum timeout can be double of timeoutS
    rbaProfileWithTimeoutIfNeeded: (data: AikyamTmxInfoResponseData, timeoutS: number): Promise<RBAProfilingResult> => {
        const buildAwaitForProfilingWithTimeout = () => {
            let timeoutHandlerId: ReturnType<typeof setTimeout>;
            let messageHandler: (event: MessageEvent<unknown>) => void;
            const passed = new Promise<RBAProfilingResult>(resolve => {
                messageHandler = (event: MessageEvent<unknown>) => {
                    // event.data will be in format "tmx_profiling_complete:<session_id>"
                    // Based on:
                    //  https://github.com/18F/identity-device-id-demo/blob/89fdac8d0c1f6fbefe2484536f7852688a09d6cb/views/index.ejs#L56-L61
                    if (
                        event.origin === window.location.origin &&
                        typeof event.data === 'string' &&
                        event.data.includes('tmx_profiling_complete:')
                    ) {
                        window.removeEventListener('message', messageHandler, false);
                        if (timeoutHandlerId) {
                            clearTimeout(timeoutHandlerId);
                        }
                        resolve({ status: 'PASSED' });
                    }
                };

                window.addEventListener('message', messageHandler, false);
            });

            const timedOut = new Promise<RBAProfilingResult>(resolve => {
                timeoutHandlerId = setTimeout(() => {
                    logger.warn('RBA Profiling timed-out');
                    window.removeEventListener('message', messageHandler, false);
                    resolve({ status: 'PROFILING_TIMED_OUT' });
                }, seconds(timeoutS));
            });
            return Promise.race([passed, timedOut]);
        };

        const loadScriptWithTimeout = (url: string): Promise<void | RBAProfilingStatus> => {
            let timeoutHandlerId: ReturnType<typeof setTimeout>;
            const timeout = new Promise<'SCRIPT_LOAD_TIMED_OUT'>(resolve => {
                timeoutHandlerId = setTimeout(() => {
                    resolve('SCRIPT_LOAD_TIMED_OUT');
                }, seconds(timeoutS));
            });

            return Promise.race([loadScript(url), timeout])
                .then(result => {
                    if (!result) {
                        clearTimeout(timeoutHandlerId);
                    }
                    return result;
                })
                .catch(error => {
                    clearTimeout(timeoutHandlerId);
                    return Promise.reject(error);
                });
        };
        return data.requireReProfile
            ? loadScriptWithTimeout(data.tmxProfileUrl)
                  .then(maybeTimeout => {
                      if (maybeTimeout === 'SCRIPT_LOAD_TIMED_OUT') {
                          return { status: maybeTimeout };
                      }
                      return buildAwaitForProfilingWithTimeout();
                  })
                  .catch((error: Error) => Promise.resolve<RBAProfilingResult>({ status: 'ERRORED', error }))
            : Promise.resolve<RBAProfilingResult>({ status: 'SKIPPED' });
    },

    /** Clear the aikyam's or the walterson's session after user logout based on the value of 'useAikyamEndpoint' flag  */
    clearIDPSessionOnLogOut: (useAikyamEndpoint = false): Promise<AxiosResponse<void>> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient).get('/rest/public/account/v2/logout'),
};

export default OBAService;
