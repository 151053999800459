import { LogLevel } from './constants';

import type { LogFn } from './types';

/**
 * Wrap a LogFn to be conditionally called at a specified log level or above
 *
 * @param level The minimu level required to call the wrapper LogFn
 * @param log The LogFn to call
 * @returns A LogFn
 */
export const logAtLevel =
    (level: LogLevel, log: LogFn): LogFn =>
    logData => {
        if (logData.level >= level) {
            log(logData);
        }
    };
