import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';

import { QaClassNames, QaIds } from 'src/app/QaConstants';
import { Button } from 'src/UI/Button';
import { useEffectOnMounted, useToggleState } from 'src/hooks';

import type { FC } from 'react';

import { CheckMark } from 'src/UI/Icon';
import { withContent } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';
import compose from 'src/utils/compose';
import { Link } from 'src/UI/Link';
import { LanguageCodeOrderMap } from 'src/utils/lang';

import type { FlowName } from 'src/app/Constants';
import type { SupportedLanguageCode, SupportedLanguageMap } from 'src/utils/lang';

import useAppStore from 'src/hooks/useAppStore';
import classes from './LanguageSelector.module.scss';

const mapContentToProps = {
    languageText: 'LblLanguage',
    languageMap: 'ListOfLanguage',
} as const;

type LanguageSelectorContentProps = {
    languageText: string;
    languageMap: SupportedLanguageMap;
};

type LanguageSelectorPublicProps = {
    supportedLanguageCodes: SupportedLanguageCode[];
    flowName: FlowName;
    buttonClassName?: string;
};

export type LanguageSelectorProps = LanguageSelectorContentProps & LanguageSelectorPublicProps & EmptyComponent;

const LanguageSelector: FC<LanguageSelectorProps> = ({
    languageText,
    languageMap,
    supportedLanguageCodes,
    flowName,
    buttonClassName,
}) => {
    const { portalBrand, lang } = useAppStore();

    const [isDropdownOpen, { on: openDropdown, off: closeDropdown, toggle: toggleDropdown }] = useToggleState();
    const [focusFirstDropdownElement, setFocusFirstDropdownElement] = useState(true);

    const buttonToggleRef = useRef<HTMLButtonElement | null>(null);
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

    useEffectOnMounted(() => {
        const closeDropdownOnClickAway = (e: MouseEvent) => {
            const target = e.target;
            if (!(target instanceof HTMLElement)) {
                return;
            }
            if (!dropdownMenuRef.current?.contains(target) && !buttonToggleRef.current?.contains(target)) {
                closeDropdown();
            }
        };
        document.addEventListener('click', closeDropdownOnClickAway);
        return () => {
            document.removeEventListener('click', closeDropdownOnClickAway);
        };
    });

    useEffect(() => {
        if (!isDropdownOpen) return;
        const menuElement = focusFirstDropdownElement
            ? dropdownMenuRef.current!.firstChild
            : dropdownMenuRef.current!.lastChild;
        if (menuElement) (menuElement as HTMLElement).focus();
    }, [isDropdownOpen, focusFirstDropdownElement]);

    const disableLeavePrompt = () => {
        onbeforeunload = null;
    };

    const handleToggleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        switch (e.key) {
            case 'ArrowDown':
            case 'ArrowUp': {
                openDropdown();
                if (e.key === 'ArrowDown') {
                    setFocusFirstDropdownElement(true);
                } else {
                    setFocusFirstDropdownElement(false);
                }
                e.preventDefault();
                e.stopPropagation();
                break;
            }
            case 'Tab':
                closeDropdown();
                break;
            default:
        }
    };

    const handleLanguageOptionTextListItemKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        // TODO figure out how to remove the type assertion since element type is provided for event value
        const elTarget = e.target as HTMLElement;
        switch (e.key) {
            case 'Tab':
                closeDropdown();
                break;
            // Note: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#whitespace_keys
            case ' ':
            case 'Enter':
                e.preventDefault();
                elTarget.click();
                break;
            case 'Escape':
                closeDropdown();
                if (buttonToggleRef.current) buttonToggleRef.current.focus();
                break;
            case 'ArrowDown':
            case 'ArrowUp': {
                const nextNode =
                    e.key === 'ArrowDown'
                        ? elTarget.nextElementSibling ?? dropdownMenuRef.current!.firstChild
                        : elTarget.previousElementSibling ?? dropdownMenuRef.current!.lastChild;
                if (nextNode) {
                    (nextNode as HTMLElement).focus();
                }
                e.preventDefault();
                break;
            }
            default:
        }
    };

    const sortedLanguageCodes = [...supportedLanguageCodes]
        .filter(langCode => languageMap[langCode] !== undefined)
        .sort((a, b) => (a === lang ? -1 : b === lang ? 1 : LanguageCodeOrderMap[a] - LanguageCodeOrderMap[b]))
        .map(langCode => [langCode, languageMap[langCode]]);

    const renderDropdownOptions = () => (
        <>
            {sortedLanguageCodes.map(([langCode, languageOptionText]) => (
                <Link
                    key={langCode}
                    hsidHref={{
                        includeBase: true,
                        flowName,
                        portalBrand,
                        lang: langCode,
                    }}
                    isExternal
                    className={classNames(classes.dropdownElement, QaClassNames.HEADER_LANGUAGE_LINK)}
                    aria-current={lang === langCode}
                    hrefLang={langCode as SupportedLanguageCode}
                    onClick={disableLeavePrompt}
                    onKeyDown={handleLanguageOptionTextListItemKeyDown}
                    role="menuitem"
                >
                    {/* TODO Remove fragment wrapper and change Link's children type to ReactNode */}
                    <>
                        {languageOptionText}
                        {lang === langCode ? <CheckMark className={classes.checkMark} /> : null}
                    </>
                </Link>
            ))}
        </>
    );

    return (
        <>
            <Button
                id={QaIds.HEADER_LANGUAGE_ACTION}
                clicked={toggleDropdown}
                variant="link-standalone"
                className={classNames(buttonClassName, classes.dropdownButton)}
                ref={buttonToggleRef}
                onKeyDown={handleToggleKeyDown}
                aria-expanded={isDropdownOpen}
                aria-controls={QaIds.HEADER_LANGUAGE_MENU}
            >
                {languageText}
            </Button>
            <div
                id={QaIds.HEADER_LANGUAGE_MENU}
                role="menu"
                className={classNames(classes.dropdown, {
                    [classes.openedDropdown]: isDropdownOpen,
                })}
                ref={dropdownMenuRef}
            >
                {renderDropdownOptions()}
            </div>
        </>
    );
};

export default compose(withContent(mapContentToProps)(LanguageSelector)) as FC<
    Omit<LanguageSelectorProps, keyof LanguageSelectorContentProps>
>;
