import React from 'react';

import { svgProps } from './utils';

const CloseIcon = () => (
    <svg {...svgProps}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.721.326a.999.999 0 010 1.414L9.428 8.012l6.29 6.273a.999.999 0 11-1.414 1.414L8.013 9.424l-6.288 6.274c-.391.39-1.024.39-1.415-.001a.999.999 0 010-1.413l6.288-6.272L.31 1.741A.999.999 0 111.723.325L8.014 6.6 14.305.325a1.001 1.001 0 011.415.001z"
        />
    </svg>
);

export default CloseIcon;
