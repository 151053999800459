import React, { AriaRole } from 'react';
import type { FC, MouseEventHandler, KeyboardEventHandler, MouseEvent, KeyboardEvent } from 'react';
import { Link as RRLink } from 'react-router-dom';
import classNames from 'classnames';
import warning from 'warning';

import AnalyticUtility, { handleAnalyticsTriggerError, LinkAnalyticsData } from 'src/app/AnalyticUtility';
import { FlowName } from 'src/app/Constants';
import noop from 'src/utils/noop';
import { buildHsidPath } from 'src/utils/urlUtility';
import type { SupportedLanguageCode } from 'src/utils/lang';
import { RdsLink } from '../RdsComponents';

const iconEnv = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';

const hsidVariants = ['graphic'] as const;
const buttonVariants = ['button-primary', 'button-primary-alt', 'button-secondary', 'button-tertiary'] as const;
const variants = [
    // Standard RDS variant(s)
    'inline',
    'external-footer',
    'external-inline',
    'icon-left',
    'footer',
    'heading',
    'standalone',
] as const;
type ButtonVariant = typeof buttonVariants[number];
type LinkVariant = typeof variants[number];
type HsidVariant = typeof hsidVariants[number];
export type Variant = ButtonVariant | LinkVariant | HsidVariant;

// Adding support for variant prop to React Router Link Component
declare module 'react-router-dom' {
    export interface LinkProps {
        variant: Variant;
    }
}

interface BaseLinkProps {
    analyticsData?: LinkAnalyticsData;
    buttonVariantSize?: 'small';
    buttonVariantWidth?: 'full';
    children: React.ReactChild;
    href?: string;
    hrefLang?: SupportedLanguageCode;
    hsidHref?: {
        flowName: FlowName;
        includeBase?: boolean;
        lang?: string;
        portalBrand: string;
        step?: string;
    };
    className?: string;
    id?: string;
    isExternal?: boolean;
    state?: Record<string, string>;
    target?: '_blank';
    variant?: Variant;
    role?: AriaRole;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
}

export interface HrefLinkProps extends BaseLinkProps {
    href: NonNullable<BaseLinkProps['href']>;
    hsidHref?: never;
}

interface HsidHrefLinkProps extends BaseLinkProps {
    href?: never;
    hsidHref: NonNullable<BaseLinkProps['hsidHref']>;
}

export type LinkProps = HrefLinkProps | HsidHrefLinkProps;

// Match origin, pathname, search, and hash
// TODO: This regex fails for domains without trailing slashes, e.g. https://www.google.com
const urlPartsRegex = /^(?:(?:https?:)?\/\/[^/]+)?(\/[^?#]*)(\?[^#])?(#.+)?/;

const absoluteUrlPrefixRegex = /^https?:\/\//;
const isAbsoluteUrl = (str = '') => absoluteUrlPrefixRegex.test(str);

const externalWarn = (external: boolean, url?: string) => {
    if (!url) return true;

    if (isAbsoluteUrl(url) && external) return true;

    return false;
};

const buildLinkToObject = (href: HrefLinkProps['href'], state: BaseLinkProps['state']) => {
    const matches = urlPartsRegex.exec(href);

    if (!matches) {
        throw new TypeError(`Unable to parse URL ${href} in 'Link'`);
    }

    const [, pathname, search, hash] = matches;

    return {
        pathname,
        search,
        hash,
        state,
    };
};

const getButtonClassNames = (
    variant: NonNullable<BaseLinkProps['variant']>,
    buttonVariantWidth?: BaseLinkProps['buttonVariantWidth'],
    buttonVariantSize?: BaseLinkProps['buttonVariantSize']
) =>
    classNames({
        'rds-primary-button': variant === 'button-primary',
        'rds-primary-alt-button': variant === 'button-primary-alt',
        'rds-secondary-button': variant === 'button-secondary',
        'rds-tertiary-button': variant === 'button-tertiary',
        'is-rds-fullwidth': buttonVariantWidth === 'full',
        'is-rds-small': buttonVariantSize === 'small',
    });

// Because you need to manually wire up history navigation, BaseLink implementation heavily borrowed from the private AnchorLink implementation in React Router
// https://github.com/ReactTraining/react-router/blob/ebd1528bafebd81ca9b813f722c8f1368bb57e7e/packages/react-router-dom/modules/Link.js
const isModifiedEvent = (ev: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) =>
    !!(ev.metaKey || ev.altKey || ev.ctrlKey || ev.shiftKey);

const BaseLink = React.forwardRef<
    HTMLAnchorElement,
    LinkProps & {
        navigate?: () => void;
        variant: NonNullable<BaseLinkProps['variant']>;
    }
>(
    (
        {
            id,
            href,
            hrefLang,
            className,
            children,
            target,
            variant,
            role,
            buttonVariantWidth,
            buttonVariantSize,
            onClick = noop,
            onKeyDown = noop,
            analyticsData,
            navigate,
            ...props
        },
        ref
    ) => {
        const handleClick: MouseEventHandler<HTMLAnchorElement> = ev => {
            onClick(ev);
            if (analyticsData) {
                AnalyticUtility.onLinkClickedData(analyticsData).catch(handleAnalyticsTriggerError(analyticsData));
            }

            if (
                // `navigate` is provided by the React Router Link
                typeof navigate === 'function' &&
                !ev.defaultPrevented && // onClick prevented default
                ev.button === 0 && // ignore everything but left clicks
                !target && // let browser handle "target=_blank" etc.
                !isModifiedEvent(ev) // ignore clicks with modifier keys
            ) {
                ev.preventDefault();
                navigate();
            }
        };

        const commonProps = {
            ...props,
            id,
            href,
            hrefLang,
            className,
            role,
            onClick: handleClick,
            onKeyDown,
            ref,
            target,
        };

        return buttonVariants.includes(variant as ButtonVariant) || variant === 'graphic' ? (
            <a
                {...commonProps}
                className={
                    // `graphic` variant signals for no custom CSS class to be applied
                    variant === 'graphic'
                        ? 'is-inline-block'
                        : getButtonClassNames(variant, buttonVariantWidth, buttonVariantSize)
                }
            >
                {children}
            </a>
        ) : (
            <RdsLink {...commonProps} variant={variant as LinkVariant} iconEnv={iconEnv} colorBranded={false}>
                {children}
            </RdsLink>
        );
    }
);

const Link: FC<LinkProps> = ({ id, isExternal = false, href, hsidHref, state, variant = 'inline', ...props }) => {
    // NOTE: The warning function from this library will trigger if the condition (first argument) is FALSE.
    // Personal note: I hate this. Why are we using this?

    // TODO prop type warning about isExternal === false && state
    warning(href !== '#!', 'Failed prop type: The `href` prop of `Link` should not be "#!".');

    warning(
        isExternal ? state === undefined : true,
        'Invalid props: Do not set `state` prop when `isExternal` is `true` in `Link`'
    );

    warning(
        // All good if there's no `href` or the `href` is external and `isExternal` flag is enabled
        externalWarn(isExternal, href),
        'Invalid props: `href` prop is an absolute URL but `isExternal` is `false`. Set `isExternal` to `true` to support offsite linking'
    );

    const finalHref = hsidHref ? buildHsidPath(hsidHref) : href;

    return isExternal || variant.indexOf('external') > -1 ? (
        <BaseLink id={id} href={finalHref} variant={variant} {...props} />
    ) : (
        <RRLink id={id} to={buildLinkToObject(finalHref, state)} variant={variant} {...props} component={BaseLink} />
    );
};

export default Link;
