import axios from 'axios';
import { getAikyamApiHost } from './utils';

import type { ServiceClient } from '../types';

const instance = axios.create({
    baseURL: getAikyamApiHost(),
    timeout: 60000,
});

instance.defaults.withCredentials = true;
instance.defaults.headers.post['Content-Type'] = 'application/json';

export default instance as ServiceClient;
