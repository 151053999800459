import { CallableLogLevel, LogLevel } from '../constants';

import type { LogFn, LogFormatter } from '../types';

type ConsoleLoggerFnName = 'debug' | 'info' | 'warn' | 'error';

const logLevelToConsoleMethodMap: Record<CallableLogLevel, ConsoleLoggerFnName> = {
    [LogLevel.DEBUG]: 'debug',
    [LogLevel.INFO]: 'info',
    [LogLevel.WARN]: 'warn',
    [LogLevel.ERROR]: 'error',
    [LogLevel.FATAL]: 'error',
} as const;

/**
 * @private Exported for test purposes
 */
export type ConsoleDriver = Pick<Console, ConsoleLoggerFnName>;

type ConsoleLoggerConfig = {
    consoleDriver?: ConsoleDriver;
    formatter?: LogFormatter;
};

const defaultConsoleLogFormatter: LogFormatter = logData => {
    const { level: _level, levelName, message, ...rest } = logData;

    return `[${levelName}] ${message} ${JSON.stringify(rest)}`;
};

/**
 * Create a log() function that outputs log information to the platform-appropriate console (browser console,
 * STDOUT/STDERR, etc)
 *
 * @public
 * @param [config.formatter] A function to format the {@see LogData} as a string
 * @param [config.consoleDriver] An object with specific console logging functions
 * @returns A log() function that outputs to the platform console
 */
export const logToConsole =
    ({ formatter = defaultConsoleLogFormatter, consoleDriver = globalThis.console }: ConsoleLoggerConfig = {}): LogFn =>
    logData => {
        consoleDriver[logLevelToConsoleMethodMap[logData.level]](formatter(logData));
    };
