import { SupportedLanguageCode, SupportedHtmlLangCode, SupportedLocaleCode } from 'src/utils/lang';

/**
 * HSID system supported languages
 * English en; Spanish es; Haitian Creole ht, Ilocano il; Korean ko; Kanuri kr; Tagalog tl; Tamil ta; Vietnamese vi; Chinese zh; Somali so; Hmong hm;
 * HSID: All possible languages in HSID (AppConstants.LANGUAGE) To be considered for future implementations when required.
 * @param {*} language
 */
export const LanguageCode = {
    EN: 'en',
    ES: 'es',
    HT: 'ht',
    IL: 'il',
    /** @deprecated Use KO  */
    KR: 'kr',
    /** @deprecated Use TL  */
    TA: 'ta',
    VI: 'vi',
    ZH: 'zh',
    SO: 'so',
    HM: 'hm',
    KO: 'ko',
    TL: 'tl',
    KC: 'kc',
} as const;

export const LanguageCodeOrderMap: Record<SupportedLanguageCode, number> = {
    en: 1,
    es: 2,
    ta: 3,
    ht: 4,
    hm: 5,
    il: 6,
    kr: 7,
    so: 8,
    tl: 9,
    ko: 10,
    kc: 11,
    vi: 12,
    zh: 13,
} as const;

// index 0 is HTML lang attribute
// index 1 is a standardized locale parameter
export const LanguageCodeMap: Record<SupportedLanguageCode, [SupportedHtmlLangCode, SupportedLocaleCode]> = {
    [LanguageCode.EN]: ['en', 'en-US'],
    [LanguageCode.ES]: ['es', 'es-ES'],
    [LanguageCode.HT]: ['fr', 'fr-FR'],
    [LanguageCode.IL]: ['fil', 'fil-PH'],
    [LanguageCode.KO]: ['ko', 'ko-KR'],
    /** @deprecated Use LanguageCode.KO  */
    [LanguageCode.KR]: ['ko', 'ko-KR'],
    /** @deprecated Use LanguageCode.TL  */
    [LanguageCode.TA]: ['fil', 'fil-PH'],
    [LanguageCode.TL]: ['fil', 'fil-PH'],
    [LanguageCode.VI]: ['vi', 'vi-VN'],
    [LanguageCode.ZH]: ['zh-CN', 'zh-Hans'],
    [LanguageCode.SO]: ['so', 'so-SO'],
    [LanguageCode.HM]: ['hmn', 'hmn'],
    [LanguageCode.KC]: ['kar', 'kar'],
};
