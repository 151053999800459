export const seconds = (n = 0): number => n * 1000;

export const minutes = (n = 0): number => n * seconds(60);

export const MAX_YEAR_OFFSET_FOR_MOBILE_DOB = 10;

/**
 * Converts the provided date string to the proper Date object.
 * See https://stackoverflow.com/a/31732581 for rationale.
 *
 * TODO: verify that date strings come back in YYYY-MM-DD format from HSID11 - this utility may not be needed
 *
 * @param dateString a date string in YYYY-MM-DD(THH:MM:SS) format
 * @returns a Date object set to the provided date
 * */
export const getDateFromString = (dateString: string): Date =>
    new Date(dateString.replace(/-/g, '/').replace(/T.+/, ''));

/**
 * Formats the date in YYYY-MM-DD format.
 * @param date
 */
export const getShortFormattedDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return [date.getFullYear(), month < 10 ? `0${month}` : month, day < 10 ? `0${day}` : day].join('-');
};

export const ymdRegex = /^\d{4}-\d{2}-\d{2}$/;
/**
 * Convert a hyphen seperated (YYYY-MM-DD) string into a slash seperated date string (MM/DD/YYYY)
 *
 * @private
 * @param dateString A YYYY-MM-DD formatted date string
 * @returns An empty string if dateString is invalid or a slash seperated date string
 * YYYY-MM-DD -> MM/DD/YYYY
 */
export const convertYmdHyphenToMdySlash = (dateString: string): string => {
    if (!ymdRegex.test(dateString)) {
        return '';
    }

    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
};

/**
 * Convert a slash seperated (MM/DD/YYYY) string into hyphen seperated date string (YYYY-MM-DD)
 *
 * @private
 * @param dateValue entered string in MM/DD/YYYY format
 * @returns A YYYY-MM-DD string
 */
export const convertMdySlashToYmdHyphen = (dateString: string): string => {
    const [month, day, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
};

/**
 *
 * @param monthString
 * @returns a boolean value based on the expected format of the month
 */
export const isValidMonth = (monthString: string): boolean => /^(0[1-9]|1[012])$/.test(monthString);

/**
 *
 * @param dayString
 * @returns a boolean value based on the field length and allows only numbers
 */
export const isValidDay = (dayString: string): boolean => /^\d{2}$/.test(dayString);

/**
 *
 * @param yearString
 * @returns a boolean value based on the field length and allows only numbers
 */

export const isValidYear = (yearString: string): boolean => /^\d{4}$/.test(yearString);

/**
 * Returns the number of the days in a month based on the year and month value that is being passed,
 * Will also check if the given year is a leap year based on the calculation and will accordingly return the number of days for
 * the month of february based on whether year comes under leap year or not.
 * Formulae used for determining the year as leap year is taken from below
 * https://codescracker.com/js/program/javascript-check-leap-year.htm
 * @param month
 * @param year
 * @returns number of days in the given month based on the month and the year.
 */

export const getDaysInMonth = (month: number, year: number): number => {
    if (month < 1 || month > 12) {
        throw new RangeError(`month argument should be 1-12, inclusive. ${month} given.`);
    }
    switch (month) {
        case 2:
            return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
        case 4:
        case 6:
        case 9:
        case 11:
            return 30;
        default:
            return 31;
    }
};

/**
 * @param now - represents current Date and should be provided only for test purposes and not in application code
 * @returns  correctly formatted date 10 years in the past
 */
export const getMaxDate = (now: Date = new Date()): string => {
    now.setFullYear(now.getFullYear() - MAX_YEAR_OFFSET_FOR_MOBILE_DOB);
    return getShortFormattedDate(now);
};

/**
 * @param {*} date to split the values into day,month and year
 * returns string format of day, month and year.
 */
export const getFormattedDate = (date: string) => {
    // concat "T00:00:00" to end of date string
    // otherwise string will be assumed at 0:00 and then converted giving wrong date
    const formattedDate = new Date(date.concat('T00:00:00'));

    // Condition check if this is a valid date input. If the date string is invalid, `formattedDate` will be an
    // object that stringifies to "Invalid Date".
    if (date.length >= 8 && formattedDate.toString() !== 'Invalid Date') {
        const month =
            formattedDate.getMonth() < 9
                ? `0${formattedDate.getMonth() + 1}`
                : (formattedDate.getMonth() + 1).toString();
        const day = formattedDate.getDate() < 10 ? `0${formattedDate.getDate()}` : formattedDate.getDate().toString();
        return [month, day, formattedDate.getFullYear().toString()];
    }
    return undefined;
};
