import { LogLevel, LogLevelName } from './constants';

/**
 * Type-safe parsing utility to convert an Env string value to a valid {@see LogLevel}
 *
 * @param envLogLevel A name string from the LogLevel enum loaded from Env
 * @returns The matching LogLevel value if `envLogLevel` is valid, `LogLevel.DISABLED` otherwise
 */
export const parseLogLevelFromEnv = (envLogLevel: string): LogLevel =>
    LogLevel[envLogLevel as LogLevelName] ?? LogLevel.DISABLED;
