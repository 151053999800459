import type { PartialExcept, PlainObject, Predicate } from 'src/app/types';
import setProp from '../setProp';

/**
 * Rich first class data type for failures of data validation
 *
 * The ValidationFailure is comprised of 3 keys:
 * - `reason`: The user-provided data that describes the failure of the validation. This is a string by default but can
 *   be any desired type.
 * - `error`: `null` or an `Error` object if the validation test threw an Error. When an error is thrown, the validation
 *   should also be considered failed.
 * - `meta`: An object of information added by the validator implementation. For example, `combineValidators()` will
 *   add a `field` key to the `meta` object with the name of the object field that experienced the failure
 *
 * This type will also serve as the base data type for form validation failures components since it provides data about
 * a validation failure which is what form error UI provides.
 *
 * @public
 */
export type ValidationFailure<R = string, M extends PlainObject = PlainObject> = {
    reason: R;
    error: null | Error;
    meta: M;
};
export const ValidationFailure = <R = string, M extends PlainObject = PlainObject>({
    reason,
    error = null,
    meta = {} as M,
}: PartialExcept<ValidationFailure<R, M>, 'reason'>): ValidationFailure<R, M> => ({
    reason,
    error,
    meta,
});

/**
 * A type for the result of a Validator function. Useful for components that expect the result of a Validator function.
 *
 * @public
 */
export type ValidationResults<R = string, M extends PlainObject = PlainObject> = ValidationFailure<R, M>[];

/**
 * The type for a validator function
 *
 * A validator function accepts a value and returns an array of zero or more ValidationFailures based on each validation
 * item that failed. A Validator function can be defined manually, or using the `createValidator()` function.
 *
 * @public
 */
export type Validator<T = any, R = string, M extends PlainObject = PlainObject> = (data: T) => ValidationResults<R, M>;

/**
 * The config arg for `createValidator()`
 *
 * @private
 */
type CreateValidatorConfig<M = PlainObject> = {
    optional?: boolean;
    optionalValues?: unknown[];
    meta?: M;
};

/**
 * Utility function to test if a value being validated is a value that can be considered optional and pass validation
 */
const baseOptionalValues = [null, undefined];
const isOptionalValue = (value: unknown, moreOptionalValues: CreateValidatorConfig['optionalValues'] = []): boolean =>
    [...baseOptionalValues, ...moreOptionalValues].includes(value);

/**
 * A convenience function to produce a Validator function
 *
 * `createValidator()` provides a declarative factory for Validator functions that follow the algorithm:
 * 1. Create an empty array to hold all validation failures
 * 2. Test the value
 * 3. If the test fails, append a message that explains the failure
 * 4. Repeat 2 and 3 as needed
 * 5. Return the array of validation failures
 *
 * Additionally, the Validator can be configured to account for values that can be optional and adding additional
 * metadata to every resulting ValidationFailure
 *
 * @public
 * @param rules An array of tuples where the first slot contains a Predicate function and the second slot contains a
 *              reason that describes the failure of the Predicate test.
 * @param config A config object to customize the behavior of the resulting validator
 * @param config.optional Whether the validator passes an optional value (default optional values are undefined, null)
 * @param config.optionalValues An array of additional values that pass for optional values. Requires config.optional to
 *                              be `true`
 * @param config.meta A dict to be added to the resulting `meta` field of the ValidationFailures. Usually this is some
 *                    additional metadata about the Validator, like the label of the associated form field value being
 *                    validated
 * @returns A Validator function
 */
export const createValidator =
    <V, R = string, M extends PlainObject = PlainObject>(
        rules: Array<[Predicate<V>, R, Partial<M>?]> = [],
        { optional = false, optionalValues = [], meta = {} as M }: CreateValidatorConfig<M> = {}
    ): Validator<V, R, M> =>
    value =>
        rules.reduce<ValidationResults<R, M>>((errs, [test, reason, ruleMeta]) => {
            // Skip validation for an unset optional value
            if (optional && isOptionalValue(value, optionalValues)) {
                return errs;
            }

            try {
                if (!test(value)) {
                    errs.push(ValidationFailure<R, M>({ reason, meta: ruleMeta ? { ...meta, ...ruleMeta } : meta }));
                }
            } catch (error) {
                errs.push(
                    ValidationFailure<R, M>({
                        reason,
                        meta: ruleMeta ? { ...meta, ...ruleMeta } : meta,
                        error: error as Error,
                    })
                );
            }

            return errs;
        }, []);

/**
 * Default Validator function that returns no validation failures
 *
 * @public
 */
export const noopValidator: Validator = () => [];

/**
 * Combine multiple Validators into a single Validator
 *
 * A compose function for Validators. Given 1 or more Validator functions with the same signature, return a new
 * Validator function with the same signature.
 *
 * @param validators One or more Validators with the same type
 * @returns A Validator that will apply all of the provided Validators to the value
 */
export const composeValidators = <V = any, R = string, M extends PlainObject = PlainObject>(
    ...validators: Validator<V, R, M>[]
): Validator<V, R, M> => {
    if (validators.length === 0) {
        // TODO Fix to not need `as unknown`
        return noopValidator as unknown as Validator<V, R, M>;
    }

    if (validators.length === 1) {
        return validators[0];
    }

    return value => validators.flatMap(validator => validator(value));
};

type CombinedValidators<Data extends PlainObject, E = string, M extends PlainObject = PlainObject> = {
    [Key in keyof Data]: Validator<Data[Key], E, M>;
};
/**
 * Create a validator for an object of data
 *
 * `combineValidators()` takes an object of string keys and Validator values and returns a Validator function that
 * takes an object of matching keys and runs validations against each key. The Validator will return an array of
 * ValidationFailures with the name of the object field included at `meta.field` of each ValidationFailure entry. This
 * utility makes it very easy to create a validation function for the whole data object for a form.
 *
 * @param validatorMap An object with string keys and Validator values
 * @returns A Validator function with each Validator provided in the `validators` arg attached as a named property
 *
 * @example
 * // Setup form state. Use `setField` to set the field value for each field by its `name`
 * const [formState, { setField }] = useObjectState({
 *     firstName: '',
 *     lastName: '',
 *     dob: '',
 * });
 *
 * // Create the form validator
 * const formValidator = combineValidators({
 *     firstName: createValidator(...),
 *     lastName: createValidator(...),
 *     dob: createValidator(...),
 * });
 *
 * // Validate the form state. If all the keys hold empty arrays, the form is valid
 * const formErrors = formValidator(formState);
 * // [{
 * //     reason: 'firstName is required',
 * //     error: null,
 * //     meta: { field: 'firstName' },
 * // }]
 *
 * // With the individual field validators set as properties on the combined validator, you can pass those as Validators
 * // to each form field component
 * <InputField name="firstName" validate={formValidator.firstName} />
 * <InputField name="lastName" validate={formValidator.lastName} />
 */
export const combineValidators = <Data extends PlainObject, R = string, M extends PlainObject = PlainObject>(
    validatorMap: CombinedValidators<Data, R, M>
): Validator<Data, R, { field: string } & M> & CombinedValidators<Data, R, { field: string } & M> => {
    // Unable to use `Object.entries<Validator<Data, R, M>>` because it fails the `ArrayLike` portion of the union type
    // Wrap the validators in `validatorMap` to add `meta.field`
    const validatorMapWithFieldMeta = Object.keys(validatorMap).reduce(
        (newMap, fieldName) =>
            // TODO Figure out how to connect `value` to `Data[Key]` from `CombinedValidators`
            setProp(newMap, fieldName, (value: any) =>
                validatorMap[fieldName](value).map(failure =>
                    ValidationFailure<R, { field: string } & M>({
                        ...failure,
                        meta: { ...failure.meta, field: fieldName },
                    })
                )
            ),
        {} as CombinedValidators<Data, R, { field: string } & M>
    );
    // First we create the combined validator function
    const validatorFn: Validator<Data, R, { field: string } & M> = value =>
        Object.entries(value)
            .map(([fieldName, fieldValue]) => {
                // NOTE I can;t figure out how to get the type to line up here so just going to deal with that later
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const fieldValidator = (validatorMapWithFieldMeta[fieldName] || noopValidator) as Validator<
                    Data,
                    R,
                    { field: string } & M
                >;
                return fieldValidator(fieldValue).map(failure =>
                    ValidationFailure<R, { field: string } & M>({
                        ...failure,
                        meta: { ...failure.meta, field: fieldName },
                    })
                );
            })
            .flat();

    // Before we return the combined validator function, we assign all of the field validators to it as matching
    // property names so that the combined validator value can also allow for calling the individual field validators.
    return Object.assign(validatorFn, validatorMapWithFieldMeta);
};
