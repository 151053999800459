import React from 'react';

import { InputField } from 'src/UI/Form';
import { noopValidator } from 'src/utils/validation';
import noop from 'src/utils/noop';
import { useComponentApi, useComponentApiDef } from 'src/hooks';
import { convertMdySlashToYmdHyphen, convertYmdHyphenToMdySlash } from 'src/utils/dateTimeUtility';

import type { FC } from 'react';
import type { Validator } from 'src/utils/validation';

import type { EmptyComponent } from 'src/app/types';
import type { BaseFormFieldProps } from './types';
import type { InputFieldApi, InputFieldProps } from './InputField';

export type DateFieldProps = BaseFormFieldProps<HTMLInputElement> &
    Pick<InputFieldProps, 'renderError' | 'placeholder'> &
    EmptyComponent;

/**
 * <DateField /> component
 */
const DateField: FC<DateFieldProps> = ({
    name,
    label,
    placeholder,
    defaultValue = '',
    labelVariant = 'above',
    wasFormSubmitted,
    required,
    readOnly,
    validate = noopValidator,
    onFocus = noop,
    onBlur = noop,
    onValidityChange = noop,
    onChange = noop,
    onValueChange = noop,
    renderError,
    api: setApi,
}) => {
    const [fieldApi, setFieldApi] = useComponentApi<Record<string, InputFieldApi>>();

    // TODO FUTURE: US5937523 - DRY useComponentApiDef
    useComponentApiDef<InputFieldApi>(
        {
            setFieldValue(newValue) {
                fieldApi[name].setFieldValue(convertYmdHyphenToMdySlash(newValue));
            },
            focus() {
                fieldApi[name].focus();
            },
            setFieldError(error) {
                fieldApi[name].setFieldError(error);
            },
            setFieldErrors(validationFailures) {
                fieldApi[name].setFieldErrors(validationFailures);
            },
        },
        setApi,
        name
    );
    return (
        <InputField
            id={name}
            placeholder={placeholder}
            name={name}
            type="date"
            mask="00/00/0000"
            autoCapitalize="off"
            label={label}
            defaultValue={convertYmdHyphenToMdySlash(defaultValue)}
            labelVariant={labelVariant}
            wasFormSubmitted={wasFormSubmitted}
            onFocus={onFocus}
            onBlur={onBlur}
            inputMode="numeric"
            required={required}
            readOnly={readOnly}
            validate={
                ((newValue: string) => validate(convertMdySlashToYmdHyphen(newValue))) as Validator<
                    string,
                    string,
                    { field: string; label: string; validationId?: string }
                >
            }
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValueChange={(newValue, fieldName, ev) =>
                onValueChange(convertMdySlashToYmdHyphen(newValue), fieldName, ev)
            }
            renderError={renderError}
            api={setFieldApi}
        />
    );
};

export default DateField;
