import React, { Suspense, lazy, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import AppStore from 'src/stores/AppStore';
import {
    PortalDataService,
    PortalConfigData,
    isQualtricsEnabled,
    getTheme,
    isNativeApp,
    getQualtricsId,
    shouldUseMultiTierRegistrationFlow,
    getHsidBrandLogoSrc,
} from 'src/features/PortalData';
import NotFound from 'src/app/NotFound';
import LoadingPage from 'src/UI/LoadingPage/LoadingPage';
import SkeletonPage from 'src/UI/SkeletonPage/SkeletonPage';
import { FlowNames } from 'src/app/Constants';
import AnalyticUtility from 'src/app/AnalyticUtility';
import { ContentProvider } from 'src/components/ContentProvider';
import { withInboundUrlRewriter } from 'src/components/InboundUrlRewriter';
import { useEffectOnMounted, useObjectState } from 'src/hooks';
import { logger } from 'src/app/Logger';
import { parseHsidPath } from 'src/utils/urlUtility';
import QualtricsUtility from 'src/app/QualtricsUtility';
import { getLangCode, getSupportedLanguage } from 'src/utils/lang';
import { shouldUseAikyamLogin, shouldUseWaltersonLogin } from 'src/features/Clients';
import { registerRoutePath } from 'src/flows/RegistrationV2';
import { signInRoutePath } from 'src/flows/SignIn/Walterson';
import isEmptyObject from 'src/utils/isEmptyObject';

import type { FC } from 'react';

import type { AppStoreContext } from 'src/stores/AppStore';
import type { EmptyObject } from 'src/app/types';
import type { SupportedLanguageCode } from 'src/utils/lang';
import { preloadImage } from './utils/html/preloadImage';

// Flow components
const ForgotUserName = lazy(() => import('src/flows/ForgotUserName/ForgotUserName'));
const Register = lazy(() => import('src/flows/Registration/Register'));
const RegistrationV2 = lazy(() => import('src/flows/RegistrationV2/lazy'));
const ResetPassword = lazy(() => import('src/flows/ResetPassword/ResetPassword'));
const SecureAuth = lazy(() => import('src/flows/SecureAuth/SecureAuth'));
const SignIn = lazy(() => import('src/flows/SignIn/SignIn'));
const VerifyLogin = lazy(() => import('src/flows/VerifyLogin/VerifyLogin'));
const Settings = lazy(() => import('src/flows/Settings/Settings'));
const FullStepUp = lazy(() => import('src/flows/FullStepUp/Full_Step_Up'));
const NoAccess = lazy(() => import('src/flows/NoAccess'));
const Evaluate = lazy(() => import('src/flows/Evaluate/Evaluate'));
const Ensure = lazy(() => import('src/flows/Ensure/Ensure'));
const SSOPage = lazy(() => import('src/flows/SSO/SSOFlow'));
const VerifyAccount = lazy(() => import('src/flows/VerifyAccount/VerifyAccount'));
const Dashboard = lazy(() => import('src/flows/Dashboard/Dashboard'));
const SignInWalterson = lazy(() => import('src/flows/SignIn/Walterson/lazy'));
const SSO = lazy(() => import('src/flows/SSO/SSO.jsx'));

/**
 * Apply changes to the DOM outside of React for setting the theme
 *
 * This function is designed to only be called once when HSID boots up and assumes that `uhc` is the default theme
 * hard-coded into the source. If the theme in the config is `uhc`, then this is a no-op. Calling this during
 * `onUpdatedData` may not update the UI as expected because a transition from `optum` -> `uhc` would produce a no-op
 * and the UI would not change.
 *
 * @param theme The theme to apply
 */
const applyTheme = (theme: ReturnType<typeof getTheme>): void => {
    if (theme !== 'uhc') {
        // We apply the theme classes to <html> element since font override selector references `body`
        // selector and the way the code is written, `.theme-<theme> body` is generated which wouldn't apply
        // the general font styling correctly.
        document.documentElement.classList.add(`theme-${theme}`);
        // Update favicon paths to theme folder
        const iconSelectors = ['apple-touch-icon', 'icon', 'mask-icon', 'shortcut icon']
            .map(rel => `link[rel="${rel}"]`)
            .join(',');
        document.head.querySelectorAll(iconSelectors).forEach(el => {
            if (el instanceof HTMLLinkElement) {
                el.setAttribute('href', el.href.replace(/\/uhc\//, `/${theme}/`));
            }
        });
    }
};

type AppProps = EmptyObject;

const App: FC<AppProps> = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [appStoreData, { set: setAppStoreData, update: updateAppStoreData }] = useObjectState<
        AppStoreContext | EmptyObject
    >();
    const { AEMData, configData } = appStoreData;

    const match = {
        url: window.location.pathname,
        params: parseHsidPath(window.location.pathname),
    };
    const portalBrand = match.params.portalBrand ?? '';
    const lang = getSupportedLanguage(match.params.lang);

    const loadPortalData = (_portalBrand: string, _lang: SupportedLanguageCode) => {
        PortalDataService.getPortalData(_portalBrand, _lang, {
            onInitialData({ config, content }) {
                const finalConfig = PortalConfigData(config);
                const theme = getTheme(finalConfig);

                setAppStoreData({
                    AEMData: content,
                    configData: finalConfig,
                    portalBrand: _portalBrand,
                    lang: _lang,
                    isPingAccessEnabled: true,
                });

                preloadImage(getHsidBrandLogoSrc(finalConfig));

                // Initialize Analytic Utilities
                // enable UHC data layer only for uhc theme
                AnalyticUtility.initialize({
                    enableUHCDataLayer: theme === 'uhc',
                    isNativeApp: isNativeApp(finalConfig),
                });

                if (isQualtricsEnabled(finalConfig)) {
                    QualtricsUtility.load(getQualtricsId(finalConfig));
                }

                applyTheme(theme);
                setIsLoading(false);
            },
            onInitialError(error) {
                logger.error('Error in AEM Content or Config service', { error });
                setIsLoading(false);
            },
            onUpdatedData({ config, content }) {
                const updatedPortalData: Partial<Pick<AppStoreContext, 'AEMData' | 'configData'>> = {};

                if (content !== null) {
                    logger.info('Updating content data');
                    updatedPortalData.AEMData = content;
                }

                if (config !== null) {
                    logger.info('Updating config data');
                    updatedPortalData.configData = PortalConfigData(config);
                }

                if (!isEmptyObject(updatedPortalData)) {
                    updateAppStoreData(updatedPortalData);
                }
            },
            onUpdatedError(error) {
                logger.warn('Error in revalidating Content or Config service', { error });
            },
        });
    };

    useEffectOnMounted(() => {
        logger.debug('Match Params', { url: { hsidParams: match } });

        // validate flow name
        if (match.params.flowName && !Object.values<string>(FlowNames).includes(match.params.flowName)) {
            setIsLoading(false);
        } else {
            loadPortalData(portalBrand, lang);
            // code to add lang attribute to html tag.
            document.documentElement.setAttribute('lang', getLangCode(lang));
        }
    });

    return isLoading ? (
        <LoadingPage />
    ) : AEMData && configData ? (
        <AppStore.Provider value={appStoreData}>
            <ContentProvider content={AEMData}>
                <Suspense fallback={<SkeletonPage />}>
                    <Switch>
                        {/* will only unmount a component when route changes */}
                        {/* will not unmount existing component it will just update existing component */}
                        <Route
                            strict
                            path={registerRoutePath}
                            component={shouldUseMultiTierRegistrationFlow(configData) ? RegistrationV2 : Register}
                        />
                        <Route strict path={`/${FlowNames.SSO}/:portal/:lang?`} component={SSO} />
                        <Route
                            strict
                            path={`/${FlowNames.FORGOT_PASSWORD}/:portal/:lang?/:step?`}
                            component={ResetPassword}
                        />
                        {/* render to be used to resolve routing issue */}
                        <Route
                            strict
                            path={`/${FlowNames.FORGOT_USERNAME}/:portal/:lang?`}
                            component={ForgotUserName}
                        />
                        <Route
                            strict
                            path={signInRoutePath}
                            component={
                                shouldUseWaltersonLogin(configData) || shouldUseAikyamLogin(configData)
                                    ? SignInWalterson
                                    : SignIn
                            }
                        />
                        <Route strict path={`/${FlowNames.VERIFY_ACCOUNT}/:portal/:lang?`} component={VerifyAccount} />
                        <Route strict path={`/${FlowNames.NO_ACCESS}/:portal/:lang?`} component={NoAccess} />
                        <Route
                            strict
                            path={`/${FlowNames.SECURE}/${FlowNames.VERIFY_LOGIN}/:portal/:lang?/:step?`}
                            component={VerifyLogin}
                        />
                        <Route
                            strict
                            path={`/${FlowNames.SECURE}/${FlowNames.FULL_STEP_UP}/:portal/:lang?`}
                            component={FullStepUp}
                        />
                        <Route
                            strict
                            path={`/${FlowNames.SECURE}/${FlowNames.SETTINGS}/:portal/:lang?`}
                            component={Settings}
                        />
                        <Route
                            strict
                            path={`/${FlowNames.SECURE}/${FlowNames.AUTH}/:portal/:lang`}
                            component={SecureAuth}
                        />
                        <Route
                            strict
                            path={`/${FlowNames.SECURE}/${FlowNames.DASHBOARD}/:portal/:lang`}
                            component={Dashboard}
                        />
                        <Route strict path={`/${FlowNames.EVALUATE}/:portal?/:lang?`} component={Evaluate} />
                        <Route strict path={`/${FlowNames.ENSURE}/:portal?/:lang?`} component={Ensure} />
                        {process.env.NODE_ENV === 'development' ? (
                            <Route strict path={`/${FlowNames.SSO}/:portal?/:lang?`} component={SSOPage} />
                        ) : null}
                        <Redirect from="/:other/:portal/:lang?" to={`/${FlowNames.LOGIN}/${portalBrand}/${lang}`} />
                    </Switch>
                </Suspense>
            </ContentProvider>
        </AppStore.Provider>
    ) : (
        <NotFound location={match.url} />
    );
};

export default withInboundUrlRewriter(App);
