import { isPhoneNumberMasked } from './isPhoneNumberMasked';

/**
 * returns value of masked phone number except the last n characters as number and remaining with 'x' replaced.
 * if value already masked, returns the value without change
 * @param {*} phoneVal
 * @param {*} unmaskedDigitsCount number of digits to show, default 4
 * @param {*} maskChar default 'x'
 */
export const maskPhoneNumber = (phoneVal: string, unmaskedDigitsCount = 4, maskChar = 'x'): string => {
    // Regex matches the phoneVal to be a valid phone number value both having Country code and vice versa
    const phoneRegEx =
        /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
    let finalPhoneNum = '';
    if (phoneRegEx.test(phoneVal)) {
        finalPhoneNum =
            phoneVal
                .substr(0, phoneVal.length - unmaskedDigitsCount)
                .split('')
                .map(() => maskChar)
                .join('') + phoneVal.substr(phoneVal.length - unmaskedDigitsCount);
    } else if (isPhoneNumberMasked(phoneVal, unmaskedDigitsCount, maskChar)) {
        // returns the phoneVal with case-match of the maskChar
        return (
            maskChar.repeat(phoneVal.length - unmaskedDigitsCount) +
            phoneVal.substr(phoneVal.length - unmaskedDigitsCount)
        );
    } else {
        finalPhoneNum = '';
    }
    return finalPhoneNum;
};
