export const QaIds = {
    // header
    HEADER_UTLITY_NAV: 'header-utility-nav',
    HEADER_LANGUAGE_ACTION: 'header-language-action',
    HEADER_LANGUAGE_MENU: 'header-language-menu',
    HEADER_LOGO_ACTION: 'header-logo-action',
    HEADER_HELP_ACTION: 'header-help-action',
    HEADER_SIGNIN_ACTION: 'header-signin-action',
    HEADER_SIGNOUT_ACTION: 'header-signout-action',
    HEADER_REGISTER_ACTION: 'header-register-action',
    // footer
    FOOTER_CONTAINER: 'footer_container',
    FOOTER_MESSAGE: 'footer-message',
    FOOTER_HELP_ACTION: 'footer-help-action',
    FOOTER_A11Y_ACTION: 'footer-a11y-action',
    FOOTER_EXTRA_LINK_TEMPLATE: 'footer-extra-link-[NUMBER]',
    // pages
    PAGE_HEADING: 'page-heading',
    PAGE_SUBHEADING: 'page-subheading',
    SIGN_IN_PAGE: 'signIn_page',
    UPDATE_TC_PAGE: 'LgnupdateTc_form',
    NO_ACCESS_PAGE: 'noAccess_page',
    COPPA_PAGE: 'coppa_form',
    REG_STEP1_PAGE: 'bigFive_page',
    REG_STEP2_PAGE: 'createAccount_page',
    REG_STEP3_PAGE: 'recoveryOptions_page',
    REG_STEP3_CONFIRM_PAGE: 'confimType_form',
    REG_STEP4_PAGE: 'confirmPhone_page',
    REG_STEP4_CONFIRM_TEXT_PAGE: 'confirmTexted_page',
    RESET_PASS_STEP3_PAGE: 'confirm-phone',
    RESET_PASS_STEP3_CONFIRM_TEXT_PAGE: 'reset-pass-step3-confirm-text-page',
    VERIFY_LOGIN_PAGE: 'verifyLogin_page',
    VERIFY_LOGIN_ALL_STEP_PAGE: 'verify-login-all-step-page',
    VERIFY_LOGIN_EMAIL_PAGE: 'verify_emailType',
    VERIFY_LOGIN_STEP2_CONFIRM_TEXT_PAGE: 'confirmTexted_page',
    CONFIRM_CALL_PHONE_PAGE: 'confirm-call-phone-dictated-page',
    // forms
    SIGN_IN_FORM: 'sign-in-form',
    UPDATE_TC_FORM: 'update-tc-form',
    COPPA_FORM: 'coppa-form',
    REG_STEP1_FORM: 'personalInfo_form',
    REG_STEP2_FORM: 'createAccount_form',
    REG_STEP3_PHONE_CONFIRM_FORM: 'step3-phone-confirm-form',
    REG_STEP3_SQA_CONFIRM_FORM: 'step3-sqa-confirm-form',
    REG_STEP4_CONFIRM_TEXT_FORM: 'confirmTexted_form',
    REG_STEP5_PHONE_PAGE: 'accountCreated_page',
    REG_STEP5_SQ_PAGE: 'almostFinished_page',
    FULL_STEP_UP_FORM: 'fullStepUppersonalInfo_form',
    RESET_PASS_STEP3_CONFIRM_TEXT_FORM: 'reset-pass-step3-confirm-text-form',
    VERIFY_LOGIN_ALL_STEP_FORM: 'verifyType_form',
    VERIFY_LOGIN_STEP2_CONFIRM_TEXT_FORM: 'confirmTexted_form',
    UPDATE_EMAIL_MODAL_FORM: 'update-email-modal-form',
    UPDATE_PHONE_MODAL_FORM: 'update-phone-modal-form',
    CONFIRM_CALL_PHONE_FORM: 'confirm-call-phone-dictated-form',
    PHONE_AUTHENTICATION_SETUP_FORM: 'phone-authentication-setup-form',
    PHONE_AUTHENTICATION_METHOD_FORM: 'phone-authentication-method-form',
    PHONE_AUTHENTICATION_CHALLENGE_FORM: 'phone-authentication-challenge-form',
    PHONE_AUTHENTICATION_CHALLENGE_FORM_SUMMARY_ERRORS: 'phone-authentication-challenge-errors',
    QUESTION_AUTHENTICATION_SETUP_FORM: 'question-authentication-setup-form',
    QUESTION_AUTHENTICATION_CHALLENGE_FORM: 'question-authentication-challenge-form',
    QUESTION_AUTHENTICATION_CHALLENGE_FORM_SUMMARY_ERRORS: 'question-authentication-challenge-errors',
    CLICK_TO_CALL_FORM: 'click-to-call-form',
    VERIFY_ACCOUNT_FORM: 'verify-account-form',
    RESET_PASSWORD_CONFIRM_SQA_FORM: 'reset-password-confirm-sqa-form',
    RESET_PASSWORD_CONFIRM_CODE_FORM: 'reset-password-confirm-code-form',
    RESET_PASSWORD_CONFIRM_PHONE_TYPE_FORM: 'reset-password-confirm-phone-type-form',
    REGISTRATION_BASIC_ACCOUNT_STEP_FORM: 'registration-basic-authentication-step-form',
    REGISTRATION_NON_MEMBER_ACCOUNT_FORM: 'registration-non-member-account-form',
    REGISTRATION_NON_MEMBER_ACCOUNT_STEP: 'registration-non-member-account-step-form',
    REGISTRATION_MEMBER_ACCOUNT_STEP: 'registration-member-account-step-form',
    REGISTRATION_EMAIL_OTP_VERIFICATION_STEP: 'registration-email-otp-verification-step',
    REGISTRATION_PROFILE_COMPLETE_STEP: 'registration-profile-complete-step',
    SQA_INSTRUCTIONS: 'sqa-instructions',
    FORGOT_USERNAME_ACCOUNT_DATA_FORM: 'forgot-username-account-data-form',

    // modals
    HELP_MODAL: 'help-modal',
    LEARN_MORE_MODAL: 'learn-more-modal',
    TRUSTED_DEVICE_MODAL: 'trusted-device-modal',
    TERMS_OF_USE_MODAL: 'terms-of-use-modal',
    PRIVACY_POLICY_MODAL: 'privacy-policy-modal',
    CONSUMER_NOTICE_MODAL: 'consumer-notice-modal',
    TEXTING_TERMS_MODAL: 'texting-terms-modal',
    UPDATE_EMAIL_MODAL: 'update-email-modal',
    UPDATE_PHONE_MODAL: 'update-phone-modal',
    USERNAME_SUGGESTIONS_MODAL: 'username-suggestions-modal',
    CLICK_TO_CALL_MODAL: 'click-to-call-modal',
    SESSION_TIMEOUT_MODAL: 'session-timeout-modal',
    SETTINGS_EDIT_USERNAME_MODAL: 'settings-edit-username-modal',
    // go-to-flow actions
    GO_TO_REGISTER_FLOW: 'go-to-register-flow',
    GO_TO_FORGOT_USERNAME_FLOW: 'go-to-forgot-username-flow',
    GO_TO_FORGOT_PASSWORD_FLOW: 'go-to-forgot-password-flow',
    GO_TO_HOME_PAGE: 'go-to-home-page',

    HELP_ACTION: 'help-action',
    TEXT_AGAIN_ACTION: 'text-again-action',
    CALL_INSTEAD_ACTION: 'call-instead-action',
    CALL_AGAIN_ACTION: 'call-again-action',
    TEXT_INSTEAD_ACTION: 'text-instead-action',
    CANCEL_ACTION: 'cancel-action',
    CONTINUE_ACTION: 'continue-action',
    SIGN_IN_NATIVE_APP_ACTION: 'sign-in-native-app-action',
    PHONE_INPUT: 'phone-input',
    REMEMBER_ME_CHECKBOX_LABEL: 'remember-me-checkbox-label',
    AGREE_CHECKBOX_LABEL: 'agree-checkbox-label',
    PHONE_LEGAL_INFO: 'phone-legal-info',
    CLICK_TO_CALL_PHONE_INPUT: 'clickToCallPhone',
    CLICK_TO_CALL_TOPIC_DISCUSSION_SELECT_INPUT: 'clickToCallTopicDiscussion',
    CLICK_TO_CALL_CALL_ME_IN_MINUTES_SELECT_INPUT: 'clickToCallTopicDiscussion',
    AUTOMATIC_DASHBOARD_REDIRECT_LABEL: 'automatic-dashboard-redirect-label',
    // banner message
    SETTINGS_BANNER: 'settingsBanner',
    SIGN_IN_BANNER: 'signinBanner',
    VERIFY_LOGIN_BANNER: 'verifyLoginBanner',
    VERIFY_ACCOUNT_BANNER: 'verifyAccountBanner',
    REG_BANNER: 'registrationBanner',
    RESET_PASSWORD_BANNER: 'resetpasswordBanner',
    FULL_STEP_UP_BANNER: 'fullStepUpBanner',
    FORGOT_USERNAME_BANNER: 'forgotUsernameBanner',
    EVALUATE_BANNER: 'evaluateBanner',
    ENSURE_BANNER: 'ensureBanner',
    DASHBOARD_BANNER: 'dashboardBanner',
    RESET_PASSWORD_OBA_BANNER: 'resetPasswordObaBanner',
    // summary error
    BIG_FIVE_FORM_SUMMARY_ERROR: 'personalInfo_errors',
    COPPA_SUMMARY_ERROR: 'coppa_errors',
    REG_STEP2_SUMMARY_ERROR: 'personalInfo_errors',
    REG_STEP3_CONFIRM_SUMMARY_ERROR: 'confirmAccount_errors',
    REG_STEP4_CONFIRM_TEXT_SUMMARY_ERROR: 'confirmText_errors',
    REG_STEP5_PHONE_SUMMARY_ERROR: 'summaryError',
    RESET_PASS_STEP2_GENERIC_SUMMARY_ERROR: 'reset-pass-step2-generic-summary-error',
    RESET_PASS_STEP3_CONFIRM_TEX_SUMMARY_ERROR: 'reset-pass-step3-confirm-text-summary-error',
    VERIFY_LOGIN_STEP2_CONFIRM_TEXT_SUMMARY_ERROR: 'summaryError',
    UPDATE_EMAIL_MODAL_SUMMARY_ERROR: 'updateEmailModal_errors',
    UPDATE_PHONE_MODAL_SUMMARY_ERROR: 'updatePhoneModal_errors',
    CONFIRM_CALL_PHONE_SUMMARY_ERROR: 'confirm-call-phone-dictated-summary-error',
    CLICK_TO_CALL_ERRORS_KEY: 'clickToCallErrors',
    REG_BASIC_ACCOUNT_FORM_SUMMARY_ERROR: 'basicAccountForm_errors',
    REG_NON_MEMBER_ACCOUNT_FORM_SUMMARY_ERROR: 'nonMemberAccountForm_errors',
    TFA_FORM_SUMMARY_ERROR: 'tfaForm_errors',
    FORGOT_USERNAME_ACCOUNT_DATA_FORM_ERROR_SUMMARY: 'forgot-username-account-data-form-error',
    VERIFICATION_TYPE_SUMMARY_ERROR: 'verificationType_errors',
    USERNAME_PAGE_SUMMARY_ERROR: 'username_page_errors',
    NO_ACCESS_PAGE_SUMMARY_ERROR: 'noAccess_page_errors',
    VALIDATE_EMAIL_SUMMARY_ERROR: 'validateEmail_errors',
    VALIDATE_USERNAME_SUMMARY_ERROR: 'validateUsername_errors',
    CONFIRM_SQA_SUMMARY_ERROR: 'confirmSqa_errors',
    RESET_SQA_SUMMARY_ERROR: 'resetSqa_errors',
    UPDATE_PASSWORD_SUMMARY_ERROR: 'update_password_errors',
    SIGN_IN_SUMMARY_ERROR: 'signIn_errors',
    UPDATE_TC_SUMMARY_ERROR: 'updateTc_errors',
    VERIFY_ALL_STEP_SUMMARY_ERROR: 'verifyAllStep_errors',

    REVIEW_FIELD_EMAIL: 'email_view',
    REVIEW_FIELD_PHONE: 'phone_view',
    AGREE_CHECKBOX_INPUT: 'termConditions',

    // Security TFA Images
    SECURITY_IMAGE_SQA: 'sqa-sec-image',
    SECURITY_IMAGE_CALL: 'call-sec-image',
    SECURITY_IMAGE_TEXT: 'text-sec-image',
    SECURITY_IMAGE_EMAIL: 'email-sec-image',
} as const;

export const QaClassNames = {
    MODAL_HEADING: 'modal-heading',
    MODAL_SUBHEADING: 'modal-subheading',
    MODAL_CLOSE_ACTION: 'modal-close-action',
    REVIEW_FIELD_EDIT_ACTION: 'review-field-edit-action',
    HEADER_LANGUAGE_LINK: 'header-language-link',
} as const;
