import { usaCountryCode } from './constants';

/**
 * Format an unformatted phone number (10 numeric characters)
 *
 * @param phoneNumber will be phone number as string ex "1231231234"
 * @param countryCode prepend the country code to the formatter phone number: "1 (123) 123-1234"
 * @param withPlus prepend "+" to the formatted phone number if countryCode is also provided: "+1 (123) 123-1234"
 */
export const getFormattedPhoneNumber = (
    phoneNumber: string,
    countryCode: string = usaCountryCode,
    withPlus = true
): string =>
    phoneNumber.length === 10
        ? [
              withPlus && countryCode ? '+' : '',
              countryCode ? `${countryCode} ` : '',
              '(',
              phoneNumber.substring(0, 3),
              ') ',
              phoneNumber.substring(3, 6),
              '-',
              phoneNumber.substring(6),
          ].join('')
        : '';
