import { sequence } from 'src/utils/sequence';

import { LogLevel } from './constants';
import { parseLogLevelFromEnv } from './utils';
import { logToConsole } from './LoggerDestination/ConsoleLogger';
import { logAtLevel } from './logAtLevel';

import type { ILogger } from './types';

type CreateLoggerConfig = {
    consoleLevel?: string;
};

const getCommonLogData = () => ({
    timestamp: new Date(),
    referrer: globalThis.location?.href ?? '<unknown>',
});

export const createLogger = ({ consoleLevel = LogLevel[LogLevel.DISABLED] }: CreateLoggerConfig): ILogger => {
    // TODO add additional log destinations to this `sequence()` call
    const log = sequence(logAtLevel(parseLogLevelFromEnv(consoleLevel), logToConsole()));

    return {
        debug(message, auditData) {
            log({
                level: LogLevel.DEBUG,
                levelName: LogLevel[LogLevel.DEBUG],
                message,
                ...getCommonLogData(),
                auditData,
            });
        },
        info(message, auditData) {
            log({
                level: LogLevel.INFO,
                levelName: LogLevel[LogLevel.INFO],
                message,
                ...getCommonLogData(),
                auditData,
            });
        },
        warn(message, auditData) {
            log({
                level: LogLevel.WARN,
                levelName: LogLevel[LogLevel.WARN],
                message,
                ...getCommonLogData(),
                auditData,
            });
        },
        error(message, auditData) {
            log({
                level: LogLevel.ERROR,
                levelName: LogLevel[LogLevel.ERROR],
                message,
                ...getCommonLogData(),
                auditData,
            });
        },
        fatal(message, auditData) {
            log({
                level: LogLevel.FATAL,
                levelName: LogLevel[LogLevel.FATAL],
                message,
                ...getCommonLogData(),
                auditData,
            });
        },
    };
};
